import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '报价单号', prop: 'qutc_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'prod_nos', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '工厂打样单号', prop: 'fequ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '核价接收人', prop: 'qutc_pricer_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '核价时间',
    prop: 'qutc_pridate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
